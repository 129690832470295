import { QueryClient } from '@tanstack/react-query';
import chatNotificationMp3 from 'Assets/audio/MessageNotification.mp3';
import videoEndedNotificationMp3 from 'Assets/audio/VideoEndedNotification.mp3';
import videoJoinNotificationMp3 from 'Assets/audio/VideoJoinNotification.mp3';
import videoLeaveNotificationMp3 from 'Assets/audio/VideoLeaveNotification.mp3';
import videoStartNotificationMp3 from 'Assets/audio/VideoStartNotification.mp3';
import type { CallLogType } from 'Components/CallLogs/types';
import {
  API_ENDPOINTS,
  NODE_ENV_LOCAL_OR_DEVELOPMENT,
  NODE_ENV_PRODUCTION,
  PRESENCE_IDLE_TIME,
  PUSHER_CHANNEL_EXTENSION,
} from 'Constants/env';
import { SELECTED_RING_DEVICE, SELECTED_SPEAKER } from 'Constants/localstorage';
import { WN_MESSAGE_CREATED_PREFIX } from 'Constants/webNotifications';

import { QUERY_KEYS as BADGE_COUNTERS_QUERY_KEYS } from 'Hooks/badgeCounters';
import { ConfigurationResponse } from 'Interfaces/apiDtos';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import {
  IConversationNotification,
  IConversationUpdatedNotification,
  IFavoritesMutedMentionNotification,
  IMessageUpdatedNotification,
  IParticipantsUpdatedNotification,
  IPinnedMessageNotification,
  IPresenceUpdated,
} from 'Interfaces/notifications';
import { IPreferences } from 'Interfaces/preferences';
import { VoicemailBadgeCounter } from 'Interfaces/voicemails';
import localforage from 'localforage';
import { get, isEmpty } from 'lodash';
import {
  action,
  autorun,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { fromPromise } from 'mobx-utils';
import { Contact } from 'Models/Contacts';
import { ConversationModel, MessageModel, PresenceModel } from 'Models/index';
import { IPinnedMessages } from 'Models/PinnedMessageModel';
import MessageStatusModel, { IMessageStatusModel } from 'Models/StatusModel';
import { QUERY_KEY_FETCH_CALL_LOGS } from 'Modules/callLogs/index.request';
import { useConversationStore } from 'Modules/conversation/index.store';
import { fetchMessage } from 'Modules/message/index.requests';
import { useNotificationStore } from 'Modules/notification/store';
import moment from 'moment-timezone';
import Pusher, { Channel } from 'pusher-js/with-encryption';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { sendIpcNewMessage } from 'Utils/ipcRendererEvents';
import { bugsnagClient } from 'Utils/logUtils';
import { logError } from 'Utils/phoneLogUtils';
import { getISOStringFromTimeUUID } from 'Utils/timeUUIDParser';
import { getBearerAuthToken } from '~/api';

// tslint:disable-next-line:no-reference
/// <reference path="../../../typings/global.d.ts"/>

export class PusherStore extends BaseStore {
  #queryClient: QueryClient;

  constructor(rootStore: RootStore, queryClient: QueryClient) {
    super(rootStore);
    this.#queryClient = queryClient;
    makeObservable(this);
    this.#queryClient = queryClient;
  }

  clearAllData = () => {
    if (this.personalChannel !== null && this.personalChannel.subscribed) {
      this.personalChannel.unbind_all();
      this.personalChannel = null;
    }
    if (this.socket !== null && this.socket.connection) {
      this.socket.disconnect();
      this.socket = null;
    }
  };

  @observable
  isOnline = true;

  @action
  setOnline = (isOnline: boolean) => {
    this.isOnline = isOnline;
  };

  @observable
  isPersonalChannelSubscribed = false;

  @action
  setPersonalChannelSubscribed = (isPersonalChannelSubscribed: boolean) =>
    (this.isPersonalChannelSubscribed = isPersonalChannelSubscribed);

  @observable
  lastVoicemailId = null;

  @action
  setLastVoicemailId = (id: number) => (this.lastVoicemailId = id);

  waitUntilPusherConnected = async () => {
    try {
      await when(
        () => {
          return this.isOnline;
        },
        { timeout: 10000 }
      );
    } catch (error) {
      logError(
        error,
        "Pusher didn't connect",
        'PusherStore',
        'waitUntilPusherConnected',
        'error'
      );
    }
  };

  waitUntilPersonalChannelSubscribed = async () => {
    try {
      await when(
        () => {
          return this.isPersonalChannelSubscribed;
        },
        { timeout: 10000 }
      );
    } catch (error) {
      logError(
        error,
        "Pusher didn't subscribe to personal channel",
        'PusherStore',
        'waitUntilPusherConnected',
        'error'
      );
    }
  };

  @action
  initialPusher = (config: ConfigurationResponse) => {
    this.socket = new Pusher(config.pusher.appKey, {
      cluster: 'mt1',
      auth: {
        headers: {
          Authorization: getBearerAuthToken(),
        },
      },
      authEndpoint: API_ENDPOINTS.PusherAuth,
      activityTimeout: 5000,
      pongTimeout: 1000,
    });
  };

  initSubscriptions = (config: ConfigurationResponse) => {
    this.initialPusher(config);
    this.socket.connection.bind('connecting_in', (delay: number) => {
      useNotificationStore.getState().addNotify({
        type: 'error',
        dismissible: true,
        title: 'Notification service reconnecting',
        message: `It wasn’t possible to connect to the notification service. Attempting to reconnect in ${delay} seconds.`,
      });
    });
    // https://pusher.com/docs/client_api_guide/client_connect#connection-status-events
    this.socket.connection.bind('state_change', (states) => {
      console.info(
        `Pusher state_change from ${states.previous} to ${states.current}`
      );
      if (states.current === 'unavailable' || !navigator.onLine) {
        this.setOnline(false);
        this.rootStore.phoneStore.setIsWebRTCReconnecting(true);
      }
      if (
        states.previous !== 'connected' &&
        states.current === 'connected' &&
        !this.isOnline &&
        navigator.onLine
      ) {
        this.rootStore.personStore.tryRefreshLocalLogin();
        this.setOnline(true);
        this.rootStore.phoneStore.setIsWebRTCReconnecting(false);
        this.rootStore.notificationStore.setIsNetworkErrorDisplayed(false);
        void this.#queryClient.invalidateQueries({
          queryKey: BADGE_COUNTERS_QUERY_KEYS.voicemails,
        });
      }
    });
    const pusherChannelExtension = PUSHER_CHANNEL_EXTENSION
      ? `${PUSHER_CHANNEL_EXTENSION}-`
      : '';
    this.personalChannel = this.socket.subscribe(
      `private-${pusherChannelExtension}a=${this.rootStore.personStore.loggedInAccountId}-p=${this.rootStore.personStore.loggedInPersonId}`
    );

    this.accountChannel = this.socket.subscribe(
      `private-${pusherChannelExtension}a=${this.rootStore.personStore.loggedInAccountId}`
    );

    this.personalChannel.bind('pusher:subscription_succeeded', () => {
      this.setPersonalChannelSubscribed(true);
    });

    const isContactInTheList = (data: any) => {
      const contact = JSON.parse(data) as Contact;
      const allContacts = this.rootStore.personStore.allContacts;
      return allContacts.find((item) => item.uuid === contact.uuid);
    };

    /** -- Contacts API -- */
    this.accountChannel.bind('ExternalContactCreated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (!isInList && !this.rootStore.personStore.isAddingContact) {
        this.rootStore.personStore.handleAddNewContactSuccess(contact);
      }
    });

    this.accountChannel.bind('ExtarnalContactUpdated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (isInList) {
        this.rootStore.personStore.handleUpdateContactSuccess(contact);
      }
    });

    this.accountChannel.bind('ExternalContactDeleted', (data: string) => {
      const isInList = isContactInTheList(data);
      if (isInList) {
        const contact = JSON.parse(data) as Contact;
        this.rootStore.personStore.handleRemoveSuccess(contact.uuid);
      }
    });

    this.personalChannel.bind('ExternalContactCreated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (!isInList && !this.rootStore.personStore.isAddingContact) {
        this.rootStore.personStore.handleAddNewContactSuccess(contact);
      }
    });

    this.personalChannel.bind('ExtarnalContactUpdated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (isInList) {
        this.rootStore.personStore.handleUpdateContactSuccess(contact);
      }
    });

    this.personalChannel.bind('ExternalContactDeleted', (data: string) => {
      const isInList = isContactInTheList(data);
      if (isInList) {
        const contact = JSON.parse(data) as Contact;
        this.rootStore.personStore.handleRemoveSuccess(contact.uuid);
      }
    });

    /** -- Presence -- */
    this.accountChannel.bind('PresenceUpdated', (data: string) => {
      const presDto = JSON.parse(data) as IPresenceUpdated;
      const presUpdate = PresenceModel.FromPusherResponseDto(presDto);
      this.rootStore.uiStore.insertLocalPushPresence(presUpdate);
    });

    this.accountChannel.bind('StatusMessageUpdated', (data: string) => {
      const presDto = JSON.parse(data) as IMessageStatusModel;
      const messStatusUpdate =
        MessageStatusModel.FromPusherResponseDto(presDto);
      this.rootStore.uiStore.insertLocalPushStatusMessage(messStatusUpdate);
    });
    /** -- Conversation -- */

    this.personalChannel.bind('ConversationJoin', (data: string) => {
      const cjnDto = JSON.parse(data) as IConversationUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('ConversationJoin', cjnDto);
      }
      // Eagerly initialize to guarantee the Unread Count shows up for newly joined Conversations
      this.rootStore.uiStore.conversationUnreadCounts.set(
        cjnDto.conversationId,
        { unreadMessages: 0, unreadMentions: 0 }
      );

      const previousConversationSnapshot =
        this.rootStore.conversationStore.selectConversationById(
          cjnDto.conversationId
        );

      // Reloads participants when user has rejoined a group
      previousConversationSnapshot?.then(({ data }) => {
        if (!data.isActiveParticipant) {
          this.rootStore.participantStore.fetchAndLoadConversationParticipants(
            cjnDto.conversationId
          );
        }
      });

      const convPbo =
        this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
          cjnDto.conversationId
        );
      convPbo.then((conv) => {
        this.rootStore.uiStore.setConversationAndTotalUnreadCount(
          conv.data.id,
          conv.data.unreadCount,
          convPbo,
          conv.data.unreadMentionsCount
        );
        conv.data.setIsActiveParticipant(true);
      });
    });

    this.personalChannel.bind('ConversationUpdated', (data: string) => {
      const cunDto = JSON.parse(data) as IConversationUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('ConversationUpdated', cunDto);
      }
      const conv = this.rootStore.conversationStore.selectConversationById(
        cunDto.conversationId
      );
      void conv?.then((c) => {
        if (cunDto.activeConference) {
          c.data.setActiveConference({
            id: cunDto.activeConference.id,
            sessionId: cunDto.activeConference.sessionId,
            provider: cunDto.activeConference.provider,
            start: cunDto.activeConference.start,
            adminId: cunDto.activeConference.adminId,
          });
        } else {
          c.data.setActiveConference(null);
        }
      });

      if (conv !== undefined && conv instanceof ConversationModel) {
        conv.setTopic(cunDto.topic);
        conv.setDescription(cunDto.description);
      } else {
        const isConversationArchived =
          !this.rootStore.conversationStore.conversationByIdRecentHist.has(
            cunDto.conversationId
          );
        void this.rootStore.conversationStore.loadConversationByIdGet(
          cunDto.conversationId,
          !isConversationArchived // if the conversation is archived, lets not reactivate it
        );
      }
    });

    this.personalChannel.bind('ConversationLeave', (data: string) => {
      const clnDto = JSON.parse(data) as IConversationNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('ConversationLeave', clnDto);
      }

      this.rootStore.conversationStore
        .selectConversationById(clnDto.conversationId)
        .then(
          ({ data }) => data.setIsActiveParticipant(false),
          (reason) =>
            useNotificationStore.getState().addNotify({
              type: 'error',
              dismissible: true,
              title: 'Error disabling conversation',
              message:
                'It wasn’t possible to disable the conversation. Please refresh the page.',
            })
        );

      this.rootStore.conversationStore
        .fetchConversationById(clnDto.conversationId)
        .then(({ lastMessage }) =>
          this.processReceivedMessage(
            { ...lastMessage, messageId: lastMessage.id },
            false,
            true
          )
        );
    });

    /** -- Message -- */
    this.personalChannel.bind('MessageCreated', async (data) => {
      const mcnDto = JSON.parse(data) as IMessageUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        const mcnRedacted = { ...mcnDto, chat: undefined, sms: undefined };
        console.debug('MessageCreated', mcnRedacted);
      }

      // show sidebar for video call if type is not AdHocScheduled (video for later)
      if (
        mcnDto.personId !== this.rootStore.personStore.loggedInPersonId &&
        mcnDto.conference &&
        mcnDto.conference.type !== 'AdHocScheduled'
      ) {
        const presenceStatus =
          this.rootStore.uiStore.selectPersonPresenceStatus(
            this.rootStore.personStore.loggedInPersonId
          ).state;
        const showNotification = presenceStatus !== 'DoNotDisturb';
        const audioNotif = new Audio(videoStartNotificationMp3);
        await this.playSound(showNotification, audioNotif);
        this.rootStore.conversationStore.addVideoConferenceToList(
          mcnDto.conference,
          mcnDto.personId
        );
      }

      const hasUserLeftConversation = !(await this.rootStore.conversationStore
        .selectConversationById(mcnDto.conversationId)
        ?.case({ fulfilled: (resp) => resp.data.isActiveParticipant }));

      return this.processReceivedMessage(
        mcnDto,
        false,
        hasUserLeftConversation
      );
    });

    /** -- Mentioned -- */
    this.personalChannel.bind('Mentioned', (data) => {
      const mcnDto = JSON.parse(data) as IMessageUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        const mcnRedacted = { ...mcnDto, chat: undefined, sms: undefined };
        console.debug('Mentioned', mcnRedacted);
      }

      return this.processReceivedMessage(mcnDto, true);
    });

    /** -- Read message -- */
    this.personalChannel.bind('ConversationViewStateUpdated', (data) => {
      const mcnDto = JSON.parse(data) as IConversationNotification;
      const { conversationId: notifConversationId } = mcnDto;

      if (
        notifConversationId &&
        this.rootStore.uiStore.selectConversationUnreadCounts(
          notifConversationId
        ).unreadMessages !== 0
      ) {
        if (
          this.rootStore.messageStore.groupedMessagesByConversationMap.has(
            notifConversationId
          )
        ) {
          this.rootStore.uiStore.setMarkedAsReadMessageId(
            notifConversationId,
            null
          );
          const existingMsgGroup =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              notifConversationId
            );
          this.rootStore.participantStore
            .updateMyLastReadMessage(
              notifConversationId,
              existingMsgGroup.NewestMessageId
            )
            .then(() =>
              this.rootStore.uiStore.setConversationAndTotalUnreadCount(
                notifConversationId,
                0,
                null,
                0
              )
            );
        }
      }
    });

    this.personalChannel.bind('CallLogCreated', async (data) => {
      const pusherEvent = JSON.parse(data) as CallLogType;
      if (pusherEvent)
        await this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FETCH_CALL_LOGS],
        });
    });

    this.personalChannel.bind('MessageUpdated', async (data) => {
      const munDto = JSON.parse(data) as IMessageUpdatedNotification;
      const utcNowStr = moment.utc().toISOString();
      const localMsg = new MessageModel({
        id: munDto.messageId,
        created: utcNowStr,
        updated: null,
        personId: munDto.personId,
        phone: munDto.phone,
        chat: munDto.chat,
        documents: munDto.documents || [],
        sms: munDto.sms,
        call: munDto.call,
        conference: munDto.conference,
        systemEvent: munDto.systemEvent,
        isDeleted: munDto.isDeleted,
        isPush: true,
        references: munDto.references,
      });
      const munRedacted = { ...munDto, chat: undefined, sms: undefined };
      const eventsWhichShouldPass = [
        'Conversation.Created',
        'Conversation.Updated',
        'Conversation.Participants.Removed',
        'Conversation.Participants.Added',
        'Conference.Created',
        'Conference.Started',
        'Conference.Stopped',
        'Conference.Attendee.Joined',
        'Conference.Attendee.Left',
      ];
      const shouldUpdate =
        munDto.updateEvents &&
        eventsWhichShouldPass.includes(
          munDto.updateEvents[munDto.updateEvents.length - 1].event
        );

      await runInAction(async () => {
        await this.rootStore.messageStore.editLocalMessage(
          munDto.conversationId,
          localMsg,
          munDto?.ancestorId
        );
      });

      // TODO: Find a way to ignore these notifications if they are the same `personId` AND the same device (how do we determine)? (RP 11/16/2017)
      if (
        munDto.personId !== this.rootStore.personStore.loggedInPersonId ||
        shouldUpdate
      ) {
        if (
          this.rootStore.conversationStore.selectConversationById(
            munDto.conversationId
          ) !== null
        ) {
          if (!NODE_ENV_PRODUCTION) {
            console.debug('MessageUpdated', munRedacted);
          }

          const pinnedMessages =
            this.rootStore.uiStore.listOfPinnedMessages.get(
              munDto.conversationId
            );

          const isPinned = pinnedMessages?.find(
            (item) => item.id === munDto.messageId
          );

          if (isPinned) {
            const updatedPinMessages = pinnedMessages.map((message) =>
              message.id !== munDto.messageId
                ? message
                : { ...message, chat: munDto.chat }
            );

            runInAction(() =>
              this.rootStore.uiStore.listOfPinnedMessages.set(
                munDto.conversationId,
                updatedPinMessages
              )
            );
          }

          const grpMsgs =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              munDto.conversationId
            );
          if (grpMsgs !== undefined) {
            const updatedMsg = grpMsgs.AllMessagesDescending.find(
              (m) => m.id === munDto.messageId
            );
            if (!isNullOrUndefined(updatedMsg)) {
              if (!isNullOrUndefined(updatedMsg.sms)) {
                updatedMsg.setSms(munDto.sms);
              } else if (!isNullOrUndefined(updatedMsg.documents)) {
                updatedMsg.setDocuments(munDto.documents);
              } else {
                updatedMsg.setChat(munDto.chat);
              }
            } else {
              console.warn(
                `PusherStore MessageUpdated: Failed to find updatedMsg ${munDto.messageId}`
              );
            }
          }

          const conferenceStopped = munDto?.updateEvents?.some(
            (el: any) => el.event === 'Conference.Stopped'
          );
          if (conferenceStopped) {
            this.rootStore.conversationStore.removeVideoConferenceFromList(
              munDto.conference?.id
            );
          }
        } else if (!NODE_ENV_PRODUCTION) {
          console.debug(
            `Ignoring MessageUpdated notification, ${munDto.conversationId} is not currently loaded.`
          );
        }
      } else if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `Ignoring MessageUpdated notification, ${munDto.personId} is the logged-in user.`
        );
      }
    });

    this.personalChannel.bind('MessageRemoved', (data) => {
      const munDto = JSON.parse(data) as IMessageUpdatedNotification;
      const redactedLocalMsg = { ...munDto, chat: undefined, sms: undefined };
      this.rootStore.messageStore.deleteLocalMessage(
        munDto.conversationId,
        munDto.messageId
      );
      // TODO: Find a way to ignore these notifications if they are the same `personId` AND the same device (how do we determine)? (RP 11/16/2017)
      if (munDto.personId !== this.rootStore.personStore.loggedInPersonId) {
        if (
          this.rootStore.conversationStore.selectConversationById(
            munDto.conversationId
          ) !== null
        ) {
          if (!NODE_ENV_PRODUCTION) {
            console.debug('MessageDeleted', redactedLocalMsg);
          }
          const grpMsgs =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              munDto.conversationId
            );
          if (grpMsgs !== undefined) {
            const updatedMsg = grpMsgs.AllMessagesDescending.find(
              (m) => m.id === munDto.messageId
            );
            if (!isNullOrUndefined(updatedMsg.sms)) {
              updatedMsg.setSms(munDto.sms);
            } else {
              updatedMsg.setChat(munDto.chat);
              updatedMsg.setDocuments([]);
              const pinnedMessages =
                this.rootStore.uiStore.listOfPinnedMessages.get(
                  munDto.conversationId
                );
              const isPinned = pinnedMessages?.find(
                (item) => item.id === munDto.messageId
              );
              if (isPinned) {
                const filteredPinMess = pinnedMessages.filter(
                  (item) => item.id !== munDto.messageId
                );
                runInAction(() =>
                  this.rootStore.uiStore.listOfPinnedMessages.set(
                    munDto.conversationId,
                    filteredPinMess
                  )
                );
              }
            }
          }
        } else if (!NODE_ENV_PRODUCTION) {
          console.debug(
            `Ignoring MessageDeleted notification, ${munDto.conversationId} is not currently loaded.`
          );
        }
      } else if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `Ignoring MessageDeleted notification, ${munDto.personId} is the logged-in user.`
        );
      }
    });

    /** -- Participant -- */
    this.personalChannel.bind('ParticipantsUpdated', (data: string) => {
      const punDto = JSON.parse(data) as IParticipantsUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('ParticipantsUpdated', punDto);
      }
      const conv = this.rootStore.conversationStore.selectConversationById(
        punDto.conversationId
      );
      if (conv !== undefined && conv.state === 'fulfilled') {
        this.processUpdatedParticipants(punDto);
      }
    });

    /** -- Remove conversation from search list -- */
    this.personalChannel.bind('ConversationRemovedFromRank', (data: string) => {
      const punDto = JSON.parse(data);
      if (
        this.rootStore.conversationStore.conversationByIdMap.has(
          punDto?.conversationId
        ) ||
        this.rootStore.conversationStore.conversationByIdRecentHist.has(
          punDto?.conversationId
        )
      ) {
        if (
          this.rootStore.conversationStore.FavoriteConversationIds.includes(
            punDto?.conversationId
          )
        ) {
          this.rootStore.conversationStore.removeConversationFromFavoritesPatch(
            punDto?.conversationId
          );
        }
        this.rootStore.conversationStore.removeLocalConversationFromList(
          punDto?.conversationId
        );
        if (!NODE_ENV_PRODUCTION) {
          console.debug('ConversationRemovedFromRank', punDto);
        }
      }
    });

    /** -- Pinned Messages -- */
    this.personalChannel.bind('MessagePinned', (data: string) => {
      const punDto = JSON.parse(data) as IPinnedMessageNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('Pinned message created', punDto);
      }
      let callText = punDto.call ? '' : null;
      const callPreText = punDto.call
        ? punDto.call?.direction === 'Incoming'
          ? 'Call from:'
          : 'Called'
        : null;
      if (punDto.call?.to.personId) {
        const personPbo = this.rootStore.personStore.selectPersonById(
          punDto.call.to.personId
        );
        personPbo.case({
          fulfilled: (resp) => (callText = resp.data.DisplayName),
        });
      } else if (punDto.call?.to.phone) {
        callText = punDto.call.to.phone;
      }
      const pinnedMessage: IPinnedMessages = {
        pinnedByPersonId: punDto.pinnedByPersonId,
        pinnedAt: punDto.pinnedAt,
        id: punDto.messageId,
        created: punDto.created,
        personId: punDto.personId,
        chat: {
          text: punDto.chat?.text,
          text_v2: punDto.chat?.text_v2,
        },
        documents: punDto.documents,
        conference: {
          displayName:
            punDto.conference?.displayName &&
            `Conference: ${punDto.conference?.displayName}`,
        },
        call: {
          text: callText && `${callPreText} ${callText}`,
        },
        references: punDto.references,
        systemEvent: { eventType: punDto.systemEvent?.eventType },
        conversationId: punDto.conversationId,
        source: punDto.source,
        sms: punDto.sms,
      };
      const pinnedMessages =
        this.rootStore.uiStore.listOfPinnedMessages.get(
          punDto.conversationId
        ) || [];
      runInAction(() => {
        const newPinnedMessageTime = new Date(pinnedMessage.created).getTime();

        const indexToInsert = pinnedMessages.findIndex(
          (message) =>
            new Date(message.created).getTime() > newPinnedMessageTime
        );

        indexToInsert > -1
          ? pinnedMessages.splice(indexToInsert, 0, pinnedMessage)
          : pinnedMessages.push(pinnedMessage);

        this.rootStore.uiStore.listOfPinnedMessages.set(
          punDto.conversationId,
          pinnedMessages
        );
      });
      document.getElementById('context-panel')?.click();
    });

    this.personalChannel.bind('MessageUnpinned', (data: string) => {
      const punDto = JSON.parse(data) as IPinnedMessageNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('Pinned message deleted', punDto);
      }
      const messages = this.rootStore.uiStore.listOfPinnedMessages.get(
        punDto.conversationId
      );
      const newData = messages.filter(
        (message) => message.id !== punDto.messageId
      );
      runInAction(() =>
        this.rootStore.uiStore.listOfPinnedMessages.set(
          punDto.conversationId,
          newData
        )
      );
      document.getElementById('context-panel')?.click();
    });

    this.personalChannel.bind('PreferencesUpdated', async (data: string) => {
      const punDto = JSON.parse(data) as IFavoritesMutedMentionNotification;
      const { muteConversationLocally } = this.rootStore.uiStore;
      const preferenceProperties = [
        'notificationAudio',
        'directMentionsOnly',
        'showCallMessagesInChat',
        'listUnreadFirst',
        'floatingSoftphone',
      ];
      if (punDto.mutedConversationIds) {
        muteConversationLocally(punDto.mutedConversationIds);
      } else if (punDto.favoriteConversationIds) {
        this.rootStore.conversationStore.loadFavoriteConversationsGet();
      } else if (
        preferenceProperties.some((prop) => punDto.hasOwnProperty(prop))
      ) {
        this.rootStore.preferenceStore.clearAllData(true);
        await this.rootStore.preferenceStore.getExistingPreferenceData();
      }
    });

    /** -- 10DLC -- */
    this.accountChannel.bind('TcrOptStatusUpdated', (messageJson: string) => {
      try {
        const { tcrOptOut, number } = JSON.parse(messageJson) as {
          tcrOptOut: boolean;
          number: string;
        };

        if (number) {
          useConversationStore
            .getState()
            .updateOptOutPhoneNumberInfo(number, tcrOptOut);
        }
      } catch (error) {
        console.error(
          `Message received in TcrOptStatusUpdated pusher message is not a valid JSON`
        );
      }
    });

    /** -- Voicemails -- */
    this.personalChannel.bind(
      'person-voicemail-created',
      (eventData: string) => {
        void this.#queryClient.setQueryData<VoicemailBadgeCounter>(
          BADGE_COUNTERS_QUERY_KEYS.voicemails,
          (prevState) => {
            if (prevState) {
              return {
                ...prevState,
                total: Math.max(prevState.total + 1, 0),
              };
            }
            return prevState;
          }
        );
      }
    );

    this.personalChannel.bind('person-voicemail-read', (eventData: string) => {
      const id = JSON.parse(eventData).id;

      if (this.lastVoicemailId === id) {
        return;
      }
      this.setLastVoicemailId(id as number);

      void this.#queryClient.setQueryData<VoicemailBadgeCounter>(
        BADGE_COUNTERS_QUERY_KEYS.voicemails,
        (prevState) => {
          if (prevState) {
            return {
              ...prevState,
              total: Math.max(prevState.total - 1, 0),
            };
          }
          return prevState;
        }
      );
    });

    this.personalChannel.bind(
      'person-new-voicemail-deleted',
      (eventData: string) => {
        void this.#queryClient.setQueryData<VoicemailBadgeCounter>(
          BADGE_COUNTERS_QUERY_KEYS.voicemails,
          (prevState) => {
            if (prevState) {
              return {
                ...prevState,
                total: Math.max(prevState.total - 1, 0),
              };
            }
            return prevState;
          }
        );
      }
    );
  };

  closeConnection = () => {
    this.socket.disconnect();
    this.setOnline(false);
  };

  openConnection = () => {
    const ps = this.rootStore.personStore;
    const cs = this.rootStore.configStore;
    if (
      ps &&
      ps.IsLoggedIn &&
      cs.signedInPersonConfig &&
      cs.signedInPersonConfig.state === 'fulfilled'
    ) {
      this.clearAllData();
      this.initSubscriptions(cs.signedInPersonConfig.value.data);
    }
  };

  createContact = (data) => {
    const parsedContact = JSON.parse(data) as Contact;
    return parsedContact?.sourceAccountId
      ? { ...parsedContact, accountId: parsedContact.sourceAccountId }
      : parsedContact;
  };

  processUpdatedParticipants = async (
    punDto: IParticipantsUpdatedNotification
  ) => {
    if (!isEmpty(punDto.added)) {
      this.rootStore.participantStore.insertLocalPushParticipants(
        punDto.conversationId,
        punDto.added
      );
    }
    if (!isEmpty(punDto.removed)) {
      const filteredRemoved = punDto.removed.filter(
        (p) => p.personId !== this.rootStore.personStore.loggedInPersonId
      );
      if (!isEmpty(filteredRemoved)) {
        this.rootStore.participantStore.removeLocalParticipants(
          punDto.conversationId,
          filteredRemoved
        );
        const isConversationArchived =
          !this.rootStore.conversationStore.conversationByIdRecentHist.has(
            punDto.conversationId
          );

        await this.rootStore.conversationStore.loadConversationByIdGet(
          punDto.conversationId,
          !isConversationArchived // if the conversation is archived, lets not reactivate it
        );
      }
    }
  };

  processAddedParticipants = (
    punDto: IParticipantsUpdatedNotification,
    justCreatedParticipantIds?: string[]
  ) => {
    let finalAdded = punDto.added;
    const jcpi = justCreatedParticipantIds || [];
    const currParticipants =
      this.rootStore.participantStore.selectParticipantsByConversationId(
        punDto.conversationId
      );
    if (
      currParticipants !== undefined &&
      currParticipants.state === 'fulfilled'
    ) {
      const currPartIds =
        currParticipants.value.data.results.map((cp) => cp.id) || [];
      finalAdded = finalAdded.filter(
        (a) => !currPartIds.includes(a.id) && !jcpi.includes(a.id)
      );
    }
    if (!isEmpty(finalAdded)) {
      this.rootStore.participantStore.insertLocalPushParticipants(
        punDto.conversationId,
        finalAdded
      );
    }
  };

  private socket: Pusher = null;
  private personalChannel: Channel = null;
  private presenceChannel: Channel = null;
  private accountChannel: Channel = null;

  playSound = async (
    showNotification: boolean,
    audioNotif: HTMLMediaElement
  ) => {
    if (showNotification) {
      const sp: string = await localforage.getItem<string>(
        SELECTED_RING_DEVICE
      );
      try {
        // @ts-ignore
        await audioNotif.setSinkId(sp || 'default');
        console.debug(
          `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
        );
      } catch (error) {
        console.debug(
          `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
        );
      }

      try {
        await audioNotif.play();
      } catch (error) {
        console.warn('Unable to play incoming tone.', error);
        /* Not allowed error is thrown when play() is considered an autoplay
          and the browser is blocking that until the user interacts with the page.
          Not supported error is thrown when audio formarts are not supported.
          Since we only use .mp3 this error will only happen in certain scenarios that we cannot control.
          Both This errors will be ignored
        */
        if (
          error.name !== 'NotAllowedError' &&
          error.name !== 'NotSupportedError'
        ) {
          bugsnagClient.notify(error, (event) => {
            event.severity = 'error';
            event.context = 'incomingCall';
          });
        }
      }
    }
  };

  @action
  ifNotRecentAdd = (
    conversationId: string,
    convResp: AxiosResponseT<ConversationModel>
  ) => {
    if (
      !this.rootStore.conversationStore.conversationByIdRecentHist.has(
        conversationId
      )
    ) {
      this.rootStore.conversationStore.conversationByIdRecentHist.set(
        conversationId,
        fromPromise.resolve({
          ...convResp,
          data: ConversationModel.FromResponseDto(convResp.data),
        })
      );
    }
  };

  private getMessageBodyFromDto = (mcnDto: IMessageUpdatedNotification) => {
    const chatText = get(mcnDto, 'chat.text');
    const smsText = get(mcnDto, 'sms.text');
    const attachments = get(mcnDto, 'documents');

    if (!chatText && !smsText && !attachments?.length) {
      return '';
    }

    return (
      chatText ||
      smsText ||
      (attachments.length === 1 ? 'shared a file' : 'shared files')
    );
  };

  private replacePersonMentionsInMessage(message: string): string {
    return message
      .split(' ')
      .map((word) => {
        if (!word.startsWith('@pr')) {
          return word;
        }

        const personId = parseInt(word.substring(3));
        if (!Number.isInteger(personId)) {
          return word;
        }

        const person =
          this.rootStore.personStore.selectPersonValueById(personId);
        if (!person?.data?.DisplayName) {
          return word;
        }

        return person.data.DisplayName;
      })
      .join(' ');
  }

  private processReceivedMessage(
    mcnDto: IMessageUpdatedNotification,
    isMention: boolean,
    isLeavingConversation?: boolean
  ) {
    const isSystemMessage =
      !isEmpty(mcnDto.systemEvent) ||
      !isEmpty(mcnDto.conference) ||
      !isEmpty(mcnDto.call);

    const localMsg = new MessageModel({
      id: mcnDto.messageId,
      created:
        mcnDto.created ||
        getISOStringFromTimeUUID(mcnDto.messageId) ||
        moment.utc().toISOString(),
      updated: null,
      personId: mcnDto.personId,
      phone: mcnDto.phone || null,
      chat: mcnDto.chat,
      documents: mcnDto.documents,
      sms: mcnDto.sms,
      call: mcnDto.call,
      conference: mcnDto.conference,
      systemEvent: mcnDto.systemEvent,
      isDeleted: mcnDto.isDeleted,
      isPush: true,
      references: mcnDto.references,
    });

    let convPromise = this.rootStore.conversationStore.selectConversationById(
      mcnDto.conversationId
    ) as PromiseLike<AxiosResponseT<ConversationModel>>;

    let newConversation = false;

    if (convPromise === null) {
      convPromise =
        this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
          mcnDto.conversationId,
          false // we don't want to reactivate archived conversations just yet; only if this is NOT a system event/message
        );
      newConversation = true;
    }

    void convPromise.then(async (conv) => {
      if (!isSystemMessage) {
        this.ifNotRecentAdd(mcnDto.conversationId, conv);
      }

      conv.data.setLastMessageDate(localMsg.created);
      conv.data.setLastMessageId(localMsg.id);

      const currentConversationId = getCurrentConversationId();

      // In the current Conversation, and it is Focused or has recent Focus/Keyboard/Mouse Activity
      const shouldClearUnreads =
        currentConversationId === mcnDto.conversationId &&
        this.rootStore.uiStore.IsFocused &&
        this.rootStore.uiStore.selectHasRecentActivityWithin(
          PRESENCE_IDLE_TIME
        );
      console.debug('isFromCurrConvWithRecentActivity', shouldClearUnreads);
      const messageMethodOrCount = shouldClearUnreads ? 0 : 'add';
      const mentionMethodOrCount = shouldClearUnreads
        ? 0
        : isMention
        ? 'add'
        : null;
      const activeConference =
        this.rootStore.conversationStore &&
        this.rootStore.uiStore.IsOnVideoConference;

      let errorFetchingFullMessage = false;
      if (mcnDto.hasMore) {
        try {
          const { chat: fullChat } = await fetchMessage(
            mcnDto.conversationId,
            mcnDto.messageId
          );

          localMsg.chat = fullChat;
          mcnDto.chat = fullChat;
        } catch (e) {
          errorFetchingFullMessage = true;
        }
      }

      // The latest `Message` Id (used withing `updateMyLastReadMessage`) is a computed value that will be re-calculated due to the `insertNewLocalOrPushMessage` above.
      void this.rootStore.messageStore
        .insertNewLocalOrPushMessage(mcnDto.conversationId, localMsg)
        .then(async () => {
          if (!shouldClearUnreads) {
            console.warn(
              `(${mcnDto.conversationId} || Message Id ${localMsg.id}) updateMyLastReadMessage was not sent because it is not a current conversation with recent activity `
            );
            return Promise.resolve(null);
          }
          if (!isLeavingConversation) {
            try {
              // Only mark as read if the message is from another user, because we're already marking our own messages as read on MessageStore.createMessagePost
              if (
                localMsg.personId !==
                this.rootStore.personStore.loggedInPersonId
              ) {
                return await this.rootStore.participantStore.updateMyLastReadMessage(
                  mcnDto.conversationId,
                  localMsg.id
                );
              }
            } catch (reason) {
              console.error(
                `processReceivedMessage: Failed to updateMyLastReadMessage: ${reason}`
              );
            }
          }
        })
        .then(async () => {
          const { directMentionsOnly } =
            this.#queryClient.getQueryData<IPreferences>(['preferences']);

          if (!isSystemMessage && !newConversation) {
            // Dont update unread count for system messages/events CUU2-2500
            await this.rootStore.uiStore.setConversationAndTotalUnreadCount(
              mcnDto.conversationId,
              messageMethodOrCount,
              convPromise,
              mentionMethodOrCount
            );
          }

          if (!isEmpty(mcnDto.systemEvent)) return;

          this.rootStore.messageStore.setNewestMessageOwnedByUserId(
            mcnDto.conversationId
          );
          // Sends if within Electron renderer
          sendIpcNewMessage();
          // If you are on the current conversation, we don't want to hit the DB to reload it
          const curConversation = getCurrentConversationId();
          // Otherwise, guarantee it is loaded (ex. it is new since you initially logged in)
          if (curConversation !== mcnDto.conversationId) {
            void this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
              mcnDto.conversationId
            );
          }

          const mutedConversation = this.rootStore.uiStore.selectIfConvMuted(
            mcnDto.conversationId
          );

          if (
            mutedConversation ||
            mcnDto.personId === this.rootStore.personStore.loggedInPersonId
          )
            return;

          const isSMSOrShowAllMentions =
            !directMentionsOnly ||
            conv.data.participants.some(({ phone }) => phone);

          const presenceStatus =
            this.rootStore.uiStore.selectPersonPresenceStatus(
              this.rootStore.personStore.loggedInPersonId
            ).state;
          const showNotification = presenceStatus !== 'DoNotDisturb';
          const { topic, grouping } = conv.data;

          // Web Notifications
          if (mcnDto.personId !== this.rootStore.personStore.loggedInPersonId) {
            const otherPerson =
              this.rootStore.personStore.selectPersonValueById(mcnDto.personId);

            // Builds the displayName
            let displayName = topic;
            if (grouping === 'OneOnOne') {
              const otherParticipants =
                this.rootStore.participantStore.selectOtherParticipants(
                  mcnDto.conversationId
                );
              if (otherParticipants?.length === 1) {
                const otherPersonId = otherParticipants[0].personId;
                displayName = otherPersonId
                  ? `${otherPerson.data.firstName} ${otherPerson.data.lastName}`
                  : otherParticipants[0].phone;
              }
            } else {
              displayName = `${topic} | ${
                otherPerson
                  ? `${otherPerson.data.firstName} ${otherPerson.data.lastName}`
                  : mcnDto.phone
              }`;
            }

            // Builds the message body description
            const currentMessageBodyDescription = this.getMessageBodyFromDto(
              mcnDto
            ) as string;
            // Replaces the person mentions with the DisplayName
            const messageBodyDescription: string =
              this.replacePersonMentionsInMessage(
                currentMessageBodyDescription
              );

            // Close existing MessageCreated notifications for this Conversation
            let hasExistingWebNotif = false;
            const hasConversationPreviouslyNotified =
              this.rootStore.notificationStore.messageCreatedWebNotifications.has(
                mcnDto.conversationId
              );
            if (hasConversationPreviouslyNotified) {
              hasExistingWebNotif =
                this.rootStore.notificationStore.messageCreatedWebNotifications.get(
                  mcnDto.conversationId
                ).length > 0;
              if (hasExistingWebNotif) {
                this.rootStore.notificationStore.closeAllMessageCreatedWebNotifications(
                  mcnDto.conversationId
                );
              }
            }

            // Builds the notification title and message
            let titleMessage = '';
            let message = '';
            let truncateMessage = false;
            let isMessageOrMention = false;

            if (
              isSMSOrShowAllMentions &&
              mcnDto.conference &&
              mcnDto.conference.adminId !==
                this.rootStore.personStore.loggedInPersonId
            ) {
              titleMessage = 'New video conference';
              message = `${displayName}: Would like you to join a video conference call?`;
            } else if (
              (isSMSOrShowAllMentions && isMention) ||
              (!isSMSOrShowAllMentions &&
                isMention &&
                grouping !== 'OneOnOne') ||
              isSMSOrShowAllMentions ||
              grouping === 'OneOnOne'
            ) {
              titleMessage = displayName;
              message = messageBodyDescription;
              truncateMessage = true;
              isMessageOrMention = true;
            } else {
              return;
            }

            showNotification &&
              !mcnDto.call &&
              mcnDto?.conference?.type !== 'AdHocScheduled' &&
              this.rootStore.notificationStore.addWebNotification(
                titleMessage,
                {
                  tag: `${WN_MESSAGE_CREATED_PREFIX}${mcnDto.conversationId}:${mcnDto.messageId}`,
                  requireInteraction: false,
                  icon: otherPerson?.data?.gravatarUrl
                    ? otherPerson.data.DisplayAvatar
                    : 'https://www.gravatar.com/avatar/?d=identicon&s=128',
                  silent: true,
                  timeout: 30000,
                  body: hasExistingWebNotif ? `${message} ` : ` ${message} `,
                  onClick: () => {
                    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
                      console.debug('mcn onClick for ' + mcnDto.conversationId);
                    }
                    if (
                      this.rootStore.conversationStore.conversationByIdRecentHist.has(
                        mcnDto.conversationId
                      )
                    ) {
                      this.rootStore.uiStore.setMessageIdToScroll(
                        mcnDto.messageId
                      );
                      this.rootStore.routerStore.push(
                        `/chat/conversations/${mcnDto.conversationId}/menu`
                      );
                    }
                  },
                  buttonLabel: isMessageOrMention ? 'Open' : undefined,
                },
                'info',
                false,
                truncateMessage
              );

            if (errorFetchingFullMessage) {
              this.rootStore.notificationStore.addNotification(
                'We had an issue loading the message, and some content might be missing.',
                'Error loading message',
                'error',
                'tr'
              );
            }
          }

          // Play Notification Audio
          if (this.rootStore.preferenceStore.preferences.notificationAudio) {
            let audioNotif: HTMLAudioElement;
            if (isSMSOrShowAllMentions && !activeConference) {
              // Play sound
              if (mcnDto.conference) {
                audioNotif = new Audio(videoStartNotificationMp3);
              } else if (
                mcnDto.systemEvent?.eventType ===
                'Conference.Participant.Joined'
              ) {
                audioNotif = new Audio(videoJoinNotificationMp3);
              } else if (
                mcnDto.systemEvent?.eventType === 'Conference.Participant.Left'
              ) {
                audioNotif = new Audio(videoLeaveNotificationMp3);
              } else if (mcnDto.systemEvent?.eventType === 'Conference.Ended') {
                audioNotif = new Audio(videoEndedNotificationMp3);
              } else {
                audioNotif = new Audio(chatNotificationMp3);
              }
              await this.playSound(showNotification, audioNotif);
            } else if (isMention && directMentionsOnly) {
              audioNotif = new Audio(chatNotificationMp3);
              showNotification &&
                localforage.getItem<string>(SELECTED_SPEAKER).then((sp) => {
                  try {
                    // @ts-ignore
                    audioNotif.setSinkId(sp || 'default').then(() => {
                      audioNotif.play();
                      console.debug(
                        `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                      );
                    });
                  } catch (error) {
                    audioNotif.play();
                    console.debug(
                      `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                    );
                  }
                });
            } else if (grouping === 'OneOnOne' && directMentionsOnly) {
              audioNotif = new Audio(chatNotificationMp3);
              showNotification &&
                localforage.getItem<string>(SELECTED_SPEAKER).then((sp) => {
                  try {
                    // @ts-ignore
                    audioNotif.setSinkId(sp || 'default').then(() => {
                      audioNotif.play();
                      console.debug(
                        `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                      );
                    });
                  } catch (error) {
                    audioNotif.play();
                    console.debug(
                      `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                    );
                  }
                });
            }
          }
        });
    });
  }

  /** this is for disconnect and reconnects the pusher if any of the observed of the properties changes on any stores */
  mobxHook = autorun((r) => {
    this.openConnection();
  });
}
